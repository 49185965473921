html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
button {
  cursor: pointer;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hero {
  margin-bottom: 8rem;
}

.hero__item {
  position: relative;
  margin-bottom: 1.2rem;
  overflow: hidden;
  will-change: flex-grow;
}
.hero__item._active .hero__ressort_item {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.hero__item:nth-child(1) .hero__nav_btn:first-child {
  opacity: 0.1;
  pointer-events: none;
}

.hero__item:nth-child(3) {
  margin-bottom: 0;
}

.hero__item:nth-child(3) .hero__nav_btn:last-child {
  opacity: 0.1;
  pointer-events: none;
}

.hero__item_inner {
  position: relative;
}

.hero__img_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__img_wrap::before,
.hero__img_wrap::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__img_wrap::before {
  background-repeat: no-repeat;
  background-size: cover;
}

.hero__img_wrap::after {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(34, 36, 51, 0)),
    to(#222433)
  );
  background: -o-linear-gradient(top, rgba(34, 36, 51, 0) 0%, #222433 100%);
  background: linear-gradient(180deg, rgba(34, 36, 51, 0) 0%, #222433 100%);
}

.hero__img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero__ressort {
  display: none;
}

.hero__ressort_item {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  height: 100%;
  will-change: transform;
  -webkit-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.hero__ressort_item:nth-child(1) {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.hero__ressort_item:nth-child(2) {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.hero__ressort_item:nth-child(3) {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hero__ressort_item:nth-child(4) {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.hero__ressort_item:nth-child(5) {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.hero__content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.6rem;
  color: #fff;
}

.hero__title {
  margin: 0;
  line-height: 1;
  font-weight: 400;
  font-family: "Stolzl", "Arial", sans-serif;
  display: block;
  font-size: 4.6rem;
  margin-bottom: 45px;
}

.hero__logo-link {
  margin-bottom: 4.3rem;
}

.hero__logo {
  display: block;
  width: 17rem;
}

.hero__logo._color {
  display: none;
}

.hero__logo._white._nautique {
  width: 16.8rem;
  margin-top: 0.8rem;
}

.hero__logo._white._centurion {
  width: 17rem;
}

.hero__logo._white._supreme {
  width: 18rem;
  margin-top: 0.8rem;
}

.hero__nav {
  display: none;
}

.hero__nav_btn {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 9.6rem;
  height: 4.8rem;
}

.hero__nav_btn:first-child {
  margin-right: 2.4rem;
}

.hero__nav-icon {
  width: 9.6rem;
  height: 4.8rem;
}

.hero__desc {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: fit-content;
  margin: 0;
  margin-left: auto;
  font-size: 1.4rem;
  line-height: 120%;
  text-wrap: wrap;
  max-width: 35rem;
}

.hero__desc span {
  display: block;
}
.hero__desc span:nth-child(2) {
  display: none;
}

@media only screen and (min-width: 768px) {
  section {
    max-width: none;
  }

  .hero__item {
    margin-bottom: 2rem;
  }

  .hero__item:nth-child(1) .hero__img_wrap::before {
    /* background-image: url("../../../../../../local/templates/rocketwake/img/hero/slide-1-bg-tablet.svg"); */
    background-position: top left;
    background-size: auto;
  }

  .hero__item:nth-child(2) .hero__img_wrap::before {
    /* background-image: url("../../../../../../local/templates/rocketwake/img/hero/slide-2-bg-tablet.svg"); */
    background-position: bottom right;
    background-size: auto;
  }

  .hero__item:nth-child(3) .hero__img_wrap::before {
    /* background-image: url("../../../../../../local/templates/rocketwake/img/hero/slide-3-bg-tablet.svg"); */
  }

  .hero__content {
    padding: 2.4rem;
  }
  .hero__title {
    font-size: 5.6rem;
    margin-bottom: 35px;
  }

  .hero__desc {
    font-size: 1.8rem;
  }
  .hero__desc span:nth-child(2) {
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .hero {
    margin-bottom: 9.4rem;
  }

  .hero__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    overflow: hidden;
  }

  .hero__item {
    width: 12rem;
    height: 50.6rem;
    margin-left: 1.6rem;
    margin-bottom: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 12rem;
    will-change: flex-grow;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: flex-grow 0.7s ease-in-out,
      -webkit-box-flex 0.7s ease-in-out;
    transition: flex-grow 0.7s ease-in-out, -webkit-box-flex 0.7s ease-in-out;
    -o-transition: flex-grow 0.7s ease-in-out;
    transition: flex-grow 0.7s ease-in-out;
    transition: flex-grow 0.7s ease-in-out, -webkit-box-flex 0.7s ease-in-out,
      -ms-flex-positive 0.7s ease-in-out;
  }

  .hero__item._active {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .hero__item._active .hero__img_wrap {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .hero__item:nth-child(1) {
    overflow: initial;
    margin-left: 0;
  }

  .hero__item:nth-child(1) .hero__ressort {
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scale(0.8, 0.6) translate(-15%, -25%);
    -ms-transform: scale(0.8, 0.6) translate(-15%, -25%);
    transform: scale(0.8, 0.6) translate(-15%, -25%);
    height: 82rem;
  }

  .hero__item:nth-child(3) .hero__ressort {
    -webkit-transform: scaleX(1.5) translate3d(7%, 0, 0);
    transform: scaleX(1.5) translate3d(7%, 0, 0);
  }

  .hero__item_inner {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #f4f4f4;
    overflow: hidden;
  }

  .hero__img_wrap {
    position: relative;
    margin-left: auto;
    border-radius: 50rem 0 0 50rem;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-transform: translate3d(40%, 0, 0);
    transform: translate3d(40%, 0, 0);
    -webkit-transition: -webkit-transform 0.7s ease-in-out;
    transition: -webkit-transform 0.7s ease-in-out;
    -o-transition: transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out, -webkit-transform 0.7s ease-in-out;
    will-change: transform;
  }

  .hero__img_wrap::before,
  .hero__img_wrap::after {
    display: none;
  }

  .hero__ressort {
    position: absolute;
    z-index: 0;
    top: 12%;
    left: 0;
    display: block;
    width: 50rem;
    height: 50rem;
  }

  .hero__content {
    position: static;
    width: auto;
    height: auto;
    padding: 3rem;
    padding-right: 0;
    color: #222433;
    margin-right: -1rem;
  }

  .hero__title {
    margin-bottom: 2.4rem;
  }

  .hero__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: auto;
  }

  .hero__desc {
    margin-left: 0;
    font-size: 1.6rem;
  }

  .history__title {
    margin-bottom: 6rem;
  }

  .history__title._mobile {
    display: none;
  }

  .history__title._desktop {
    display: block;
  }
}
@media only screen and (min-width: 1920px) {
  .hero__item {
    height: 75rem;
  }
  .hero__desc {
    font-size: 2.4rem;
  }
}
