.bg {
  background-color: rgba(10, 8, 23, 0.65);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  opacity: 1;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  .close {
    position: absolute;
    top: 3.2rem;
    right: 3.2rem;
    width: 4.8rem;

    height: 4.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .main {
    padding: 3.2rem;
    background-color: #fff;
    border-radius: 1.2rem;
    max-width: 90%;
    h2 {
      font-family: Stolzl-Light;
      margin-bottom: 0.8rem;
      font-size: 3.2rem;
    }
    p {
      font-family: Stolzl-Light;
      margin-bottom: 2.4rem;
      font-size: 1.4rem;
      line-height: 120%;
    }
    form {
      input {
        margin-bottom: 0.8rem;
        font-size: 1.6rem;
        padding: 1.2rem;
        width: 100%;
        line-height: 1;
        color: #222433;
        border: 1px solid #d4d4d4;
        border-radius: 4px;
        background-color: #fff;
        outline: none;
        transition: border-color 0.3s;
      }
      .inputError {
        border: 1px solid red;
      }
      button {
        width: 100%;
        margin-top: 0.8rem;
        display: inline-block;
        padding: 1.1rem 1.1rem;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: 500;
        text-decoration: none;
        background-color: #222433;
        border-radius: 0.4rem;
        text-align: center;
        border: 1px solid #222433;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .bg {
    .close {
      top: 1.2rem;
      right: 0.2rem;
    }
  }
}
