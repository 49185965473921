.container {
  .titleBg {
    height: 10rem;
    margin-bottom: 12rem;
    background-size: 100% auto;
    background-image: url(../../assets/slider/slider2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
    .titleBlock {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      h1 {
        font-size: 56px;
        color: #fff;
      }
    }
  }

  .main {
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 100px;
    .nav {
      top: calc(50vh - 25.2rem);
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-right: 6rem;
      padding: 0;
      flex-shrink: 0;
      border: none;
      position: sticky;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      span {
        margin: 1.4rem 0;
        text-transform: uppercase;
        white-space: nowrap;
        transition: 0.3s;
        cursor: pointer;
        font-size: 2.4rem;
        color: #000;
        opacity: 0.25;
      }
      span.active {
        opacity: 1;
        text-decoration: underline;
      }
    }
    .content {
      display: flex;
      width: 100%;
      min-height: 400px;
      height: 100%;
      flex-direction: column;
      h1 {
        font-size: 50px;
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .titleBg {
      .titleBlock {
        h1 {
          font-size: 46px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    .titleBg {
      margin-bottom: 2rem;
      .titleBlock {
        h1 {
          font-size: 40px;
        }
      }
    }
    .main {
      flex-direction: column;
      .nav {
        top: 80px;
        background-color: #fff;
        border-bottom: 1px solid #e3e4e6;
        z-index: 2;
        display: flex;
        flex-direction: row;
        margin: 0;
        column-gap: 20px;
        overflow: auto;
        max-width: 100%;
      }
    }
  }
  /* width */
  .nav::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  .nav::-webkit-scrollbar-track {
    background: rgba(34, 36, 50, 0.1);
  }

  /* Handle */
  .nav::-webkit-scrollbar-thumb {
    background: rgba(34, 36, 50, 0.6);
  }

  /* Handle on hover */
  .nav::-webkit-scrollbar-thumb:hover {
    background: rgba(34, 36, 50, 0.9);
  }
}
@media only screen and (max-width: 768px) {
  .container {
    .titleBg {
      .titleBlock {
        h1 {
          font-size: 30px;
          text-align: center;
        }
      }
    }
    .main {
      flex-direction: column;
      .nav {
        span {
          margin: 1.3rem 0;
          font-size: 2rem;
        }
      }
      .content {
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}
