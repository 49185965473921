.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 100px 0;
  align-items: flex-end;
  h4 {
    font-size: 64px;
    width: 50%;
  }
  p {
    font-size: 18px;
    font-family: "Stolzl-Light";
    width: 50%;
    text-align: end;
  }
}
.images {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  img {
    width: calc(25% - 10px);
    height: auto;
    object-fit: cover;
  }
}
.callbackBtn {
  background-color: rgba(34, 36, 50, 1);
  padding: 10px 26px 10px 26px;
  cursor: pointer;
  width: max-content;
  margin: 0 auto 40px;
  display: flex;
  span {
    color: #fff;
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    h4 {
      text-align: center;
      font-size: 4rem;
      margin-bottom: 3.2rem;
    }
    p {
      text-align: center;
      font-size: 1.4rem;
      line-height: 160%;
      text-indent: 0;
      width: 80%;
    }
  }
  .images {
    flex-direction: column;
    img {
      width: 75%;
      margin-bottom: 5px;
      margin: 5px auto;
    }
  }
}
@media only screen and (max-width: 460px) {
  .header {
    h4 {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
}
