.container {
  display: flex;
  margin-top: 70px;
  margin-bottom: 70px;
  flex-direction: column;
  flex: 1;

  .main {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-bottom: 40px;
    column-gap: 20px;
    row-gap: 50px;
    .content {
      display: flex;
      flex: 1;
      flex-direction: column;

      h2 {
        font-size: 48px;
      }
      h3 {
        font-size: 24px;
        margin-top: 57px;
        margin-bottom: 40px;
      }
      .address {
        font-size: 18px;
        margin-bottom: 20px;
        font-family: Stolzl-Book;
      }
      .contactsMain {
        display: flex;
        flex-direction: row;
        .tels {
          display: flex;
          flex-direction: column;
          align-items: end;
          margin-right: 88px;
          span {
            font-size: 18px;
            font-family: Stolzl-Book;
          }
        }
        .mails {
          display: flex;
          flex-direction: column;
          align-items: end;
          span {
            font-size: 18px;
            font-family: Stolzl-Book;
          }
        }
      }
      .btns {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-top: 30px;
        a {
          display: flex;
          width: 240px;
          height: 45px;
          border: 1px solid rgba(217, 217, 217, 1);
          border-radius: 2px;
          justify-content: center;
          align-items: center;
          span {
            color: rgba(61, 63, 75, 1);
            font-size: 16px;
            font-family: Stolzl-Medium;
          }
        }
      }
    }
    .map {
      display: flex;
      flex: 1;
      min-height: 500px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    .main {
      flex-direction: column;
      .content {
        h2 {
          font-size: 40px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .container {
    .main {
      .content {
        h2 {
          font-size: 35px;
        }
        h3 {
          font-size: 20px;
          margin-top: 30px;
          margin-bottom: 15px;
        }

        .address {
          font-size: 16px;
        }
        .contactsMain {
          .tels {
            margin-right: 40px;
            span {
              font-size: 16px;
            }
          }
          .mails {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .container {
    .main {
      .content {
        .contactsMain {
          flex-direction: column;
          .tels {
            align-items: flex-start;
            margin-right: 0;
            margin-bottom: 10px;
            span {
              font-size: 16px;
            }
          }
          .mails {
            align-items: flex-start;
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
