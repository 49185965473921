  @keyframes wave {
   to {
     -webkit-backface-visibility: hidden;
     -webkit-transform: translateZ(0) scale(1, 1);
     transform: translate3d(-15rem, 0, 0);
     opacity: 0;
     -webkit-filter: blur(8px);
     filter: blur(8px);
   }
   
 }

.main {
   width: calc(50% - 10px);
   height: 224px;
   display: flex;
   flex-direction: row;
   background-color: rgba(244, 244, 244, 1);
   justify-content: space-between;
   border-radius: 15px;
   overflow: hidden;
   cursor: pointer;
   position: relative;

   .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 0 15px 20px;

      h2 {
         font-size: 24px;
      }
      span {
         font-size: 12px;
      }
   }  
   .imgBlock {
      width: 60%;
      height: 100%;
      position: relative;
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         position: relative;
         z-index: 1;
         border-radius: 100em 4px 4px 100em;
      }
   }
  
   .imgBlock::after {
      content: '';
      position: absolute;
      top: 0;
      left: 1px;
      width: 35rem;
      height: 100%;
      background: -webkit-gradient(linear, left top, right top, color-stop(3.07%, #8F7578), color-stop(23.08%, rgba(143, 117, 120, 0)));
      background: -o-linear-gradient(left, #8F7578 3.07%, rgba(143, 117, 120, 0) 23.08%);
      background: linear-gradient(90deg, #8F7578 3.07%, rgba(143, 117, 120, 0) 23.08%);
      border-radius: 100em 0 0 100em;
      will-change: opacity, transform;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
   }

   
}
.animationActive .imgBlock::after {
   -webkit-animation-name: wave;
   animation-name: wave;
   -webkit-animation-duration: 1s;
   animation-duration: 1s;
 }   

 @media only screen and (max-width: 1100px) {
   .main {
      width: 100%;
      .content {
         width: 50%;
      }
      .imgBlock {
         width: 50%;
      }
   }

 }
 @media only screen and (max-width: 768px) {
   .main {
      .content {
         width: 40%;
      }
      .imgBlock {
         width: 60%;
      }
   }
 }
 @media only screen and (max-width: 600px) {
   .main {
      width: 80%;
      max-width: 50rem;
      margin: 0 auto;
      height: auto;
      flex-direction: column;
      border-radius: 5px;
      .content {
         width: 100%;
      }
      .imgBlock {
         width: 100%;
         img {
            border-radius: 40em 40em 4px 4px / 4px 4px 4px 4px;
            -webkit-clip-path: circle(700px at 50% 700px);
            clip-path: circle(700px at 50% 700px);
            max-width: 100%;
            height: auto;
            max-height: 23rem;
         }
      }
   }
 }
 @media only screen and (max-width: 480px) {
   .main {
      width: 100%;
      .imgBlock {
         img {
            max-height: 21rem;
         }
      }
   }
 }