
/* FONTS */
@font-face {
  font-family: 'Stolzl-Bold';
  src: local('Stolzl-Bold'), url(./assets/fonts/Stolzl-Bold.ttf) format('opentype');/* 700 */
}
@font-face {
  font-family: 'Stolzl-Medium';
  src: local('Stolzl-Medium'), url(./assets/fonts/Stolzl-Medium.ttf) format('opentype'); /* 500 */
}
@font-face {
  font-family: 'Stolzl-Regular';
  src: local('Stolzl-Regular'), url(./assets/fonts/Stolzl-Regular.ttf) format('opentype');/* 400 */
}
@font-face {
  font-family: 'Stolzl-Book';
  src: local('Stolzl-Book'), url(./assets/fonts/Stolzl-Book.ttf) format('opentype'); /* 350 */
}
@font-face {
  font-family: 'Stolzl-Light';
  src: local('Stolzl-Light'), url(./assets/fonts/Stolzl-Light.ttf) format('opentype'); /* 300 */

}
@font-face {
  font-family: 'Stolzl-Thin';
  src: local('Stolzl-Thin'), url(./assets/fonts/Stolzl-Thin.ttf) format('opentype'); /* 100 */
}
/* FONTS END */

/* GLOBAL STYLES */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Stolzl-Regular, Arial, Arial, serif;
  color:  rgba(0, 0, 0, 1);
}
a {
  text-decoration: none;
  color:  rgba(0, 0, 0, 1);
}
/* GLOBAL STYLES END */

/* REPEATED STYLES */
.wrapper {
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.4rem;
  max-width: 1920px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(227, 228, 230, 1);
}





/* REPEATED STYLES END */