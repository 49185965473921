.main {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;

  nav {
    display: flex;
    flex: 1;
    flex-direction: row;
    column-gap: 40px;

    span {
      font-size: 16px;
    }
  }
  .callbackBtnContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;

    .callbackBtn {
      background-color: rgba(34, 36, 50, 1);
      padding: 10px 26px 10px 26px;
      cursor: pointer;
      span {
        color: #fff;
        font-size: 16px;
      }
    }
    .burgerMenu {
      margin-left: 2.4rem;
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1.6rem;
        height: 1.3rem;
        fill: #222433;
      }
    }
  }

  .logo {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 40px;
      text-transform: uppercase;
    }
  }
}
.main::after {
  content: "";
  left: 2.4rem;
  right: 2.4rem;
  height: 1px;
  background-color: rgba(227, 228, 230, 1);
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .main {
    .logo {
      flex: 0;
      text-wrap: nowrap;
    }
  }
}
@media only screen and (max-width: 768px) {
  .main {
    margin-bottom: 20px;
    .callbackBtnContainer {
      .callbackBtn {
        padding: 1.1rem 1.1rem;
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}
