@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.main {
  margin-top: 60px;
  height: 160px;
  background-color: rgba(235, 228, 229, 1);
  display: flex;

  .runningLine {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 65px;
    column-gap: 30px;
    white-space: nowrap;
    margin: 0 auto;
    // animation: marquee 10s linear infinite;
    //  animation: 10s linear 0s infinite normal none running marquee;
    h3 {
      font-size: 56px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .main {
    .runningLine {
      padding: 0 25px;
      column-gap: 10px;
      h3 {
        font-size: 35px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .main {
    .runningLine {
      padding: 0;
      column-gap: 0;
      svg {
        display: none;
      }
      h3 {
        font-size: 26px;
      }
    }
  }
}
