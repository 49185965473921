.title {
  width: 452px;
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-top: 90px;
  margin-bottom: 70px;

  span {
    font-size: 56px;
  }
  :nth-child(2) {
    align-self: flex-end;
  }
}
.main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
@media only screen and (max-width: 768px) {
  .title {
    width: 60%;
    margin-left: 20px;
    margin-right: 0;
    margin-top: 30px;
    span {
      font-size: 4rem;
    }
  }
  .main {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .title {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    span {
      font-size: 4rem;
    }
  }
}
@media only screen and (max-width: 640px) {
  .title {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    span {
      font-size: 4rem;
    }
  }
}
