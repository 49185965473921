.main {
  .titleBlock {
    width: 1000px;
    text-align: justify;
    margin: 240px auto;
    h1 {
      font-size: 64px;
      width: 100%;
      display: flex;
      flex-direction: column;
      :nth-child(2) {
        align-self: flex-end;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 80px;
  }
  .block {
    display: flex;
    flex-direction: row;
    background-color: rgba(235, 228, 229, 1);
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
    p {
      width: 100%;
      height: 100%;
      padding: 40px;
      font-size: 21px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .main {
    .titleBlock {
      width: 100%;
      text-align: justify;
      margin: 240px auto;
      h1 {
        font-size: 64px;
        width: 100%;
        display: flex;
        flex-direction: column;
        :nth-child(2) {
          align-self: flex-end;
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      margin-bottom: 80px;
    }
    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: rgba(235, 228, 229, 1);
      width: 100%;
      height: 300px;
      margin-bottom: 20px;
      padding: 25px;
      column-gap: 25px;
      img {
        height: 100%;
        width: auto;
        object-fit: contain;
        margin: 0;
      }
      p {
        width: 100%;
        height: 100%;
        padding: 0;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    .titleBlock {
      margin: 140px auto;
      h1 {
        font-size: 44px;
      }
    }
    img {
      margin-bottom: 40px;
    }
    .block {
      display: flex;
      flex-direction: column;
      background-color: rgba(235, 228, 229, 1);
      width: 100%;
      height: auto;
      padding: 10px;
      img {
        height: auto;
        width: 100%;
        object-fit: contain;
      }
      p {
        width: 100%;
        height: 100%;
        padding: 10px;
        font-size: 16px;
      }
    }
    .block2 {
      flex-direction: column-reverse;
    }
  }
}

@media only screen and (max-width: 530px) {
  .main {
    .titleBlock {
      width: 100%;
      margin: 100px auto;
      h1 {
        font-size: 18px;
      }
    }
    img {
      margin-bottom: 10px;
    }
    .block {
      p {
        font-size: 14px;
      }
    }
  }
}
