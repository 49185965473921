.main {
  position: fixed;
  top: 0;
  right: -100%;
  left: 100%;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(var(2.06px, 1vh) * 100);
  overflow: auto;
  padding-bottom: 3.2rem;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 1300;
  background-color: #fff;
  .header {
    margin-left: auto;
    margin-right: auto;
    padding: 0 2.4rem;
    max-width: 1920px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 4.8rem;
    padding: 1.5rem 1.6rem;
    border-bottom: 1px solid #e3e4e6;
    width: 100%;
    .closeBtn {
      padding: 0;
      border: none;
      background-color: transparent;
      outline: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
      svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: #222433;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 0;
    row-gap: 4rem;
    width: 100%;
    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      row-gap: 4rem;
      align-items: flex-start;
      span {
        font-size: 3.2rem;
        text-wrap: nowrap;
      }
    }
  }
  .callbackBtn {
    margin-top: 2.4rem;
    width: calc(100% - 4.8rem);
    margin-top: auto;
    padding: 1.6rem;
    text-decoration: none;
    background-color: #222433;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 500;
      color: #fff;
    }
  }
}

#open {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
@media only screen and (max-width: 768px) {
  .main {
    .content {
      flex-direction: column;
      flex: 0;
      row-gap: 4rem;
    }
  }
}
