.main {
  margin-bottom: 12rem;
  margin-top: 20px;
  .titleBlock {
    background-image: url(./assets/bg-desktop.svg);
    background-position: top right 22.4%;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    h2 {
      max-width: 65.7rem;
      margin-left: 15.6rem;
      font-size: 56px;
      display: flex;
      flex-direction: column;
      :nth-child(2) {
        align-self: flex-end;
      }
    }
  }
  .row {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    .verticalLine {
      width: 1px;
      height: 100%;
      background-color: rgba(227, 228, 230, 1);
    }
    .block {
      width: calc(100% / 3);
      padding: 6.4rem 3.2rem;

      position: relative;

      .image {
        display: block;
        margin: 0 auto;
        width: 130px;
        height: 130px;
        // background-color: darkgray;
        margin-bottom: 2.4rem;
      }
      .text {
        display: block;
        margin: 0 auto;
        font-size: 24px;
        max-width: 30.5rem;
        text-align: center;
      }
    }
    .block::before {
      content: "";
      position: absolute;
      top: 50%;
      height: 90%;
      width: 1px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: #e3e4e6;
      left: 0;
    }
    .block:nth-child(3)::after {
      content: "";
      position: absolute;
      top: 50%;
      height: 90%;
      width: 1px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: #e3e4e6;
      right: 0;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .main {
    .row {
      flex-direction: column;
      margin: 20px 0;
      .block {
        width: 100%;
        border-bottom: 1px solid #e3e4e6;
      }
      .block:nth-child(1) {
        border-top: 1px solid #e3e4e6;
      }
      .block::after {
        content: "";
        position: absolute;
        top: 50%;
        height: 90%;
        width: 1px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background-color: #e3e4e6;
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    .titleBlock {
      h2 {
        max-width: 65.7rem;
        margin-left: 4.6rem;
        font-size: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .main {
    .titleBlock {
      h2 {
        font-size: 2.5rem;
      }
    }
  }
}
