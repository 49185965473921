.main {
  margin-top: 50px;
  margin-bottom: 100px;
  h2 {
    font-size: 64px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    :nth-child(2) {
      align-self: flex-end;
    }
  }
  .content {
    margin-top: 90px;
    display: grid;
    grid-gap: 4.8rem;
    grid-template-columns: repeat(2, 1fr);
    max-width: 99rem;
    margin-bottom: 10rem;
    margin-left: auto;
    margin-right: auto;

    p {
      font-size: 1.6rem;
    }
  }
  .imgBlock {
    position: relative;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    p {
      position: absolute;
      right: 20px;
      bottom: 20px;
      max-width: 50%;
      color: #fff;
      font-size: 16px;
      text-align: end;
    }
  }
}
@media only screen and (max-width: 768px) {
  .main {
    h2 {
      font-size: 4rem;
      max-width: 70%;
    }
    .content {
      margin-top: 40px;
      grid-gap: 0.8rem;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 10rem;

      p {
        font-size: 1.8rem;
      }
    }
    .imgBlock {
      img {
        height: 180px;
        object-fit: cover;
      }
      p {
        right: 10px;
        bottom: 10px;
        max-width: 60%;
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .main {
    h2 {
      font-size: 4rem;
      max-width: 100%;
    }
    .content {
      p {
        font-size: 1.6rem;
      }
    }
    .imgBlock {
      img {
        height: 200px;
      }
      p {
        max-width: 80%;
        font-size: 14px;
      }
    }
  }
}
