.main {
   width: calc(50% - 10px);
   height: 224px;
   background-color: rgba(34, 36, 51, 1);
   border-radius: 15px;
   padding: 13px 10px;
   color:#fff;
   .title {
      font-size: 3rem;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 0.4rem;
   }
   .subTitle {
      margin-bottom: auto;
      max-width: 44rem;
      font-size: 1.4rem;
      line-height: 120%;
   }
   form {
      input {
         margin-top: 1.2rem;
         margin-right: 1.2rem;
         width: calc(50% - 1.2rem);
         margin-bottom: 0;
         color: #fff;
         padding: 1rem;
         font-size: 1.4rem;
         line-height: 1;
         border: 1px solid rgba(255, 255, 255, 0.12);
         border-radius: 4px;
         background-color: #222433;
         outline: none;
         -webkit-transition: border-color .3s;
         -o-transition: border-color .3s;
         transition: border-color .3s;
         overflow: hidden;
         -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
      input:focus,input:not(:placeholder-shown) {
            border-color: #fff;

      }
      .btn {
         color: #222433;
         background-color: #fff;
         border-color: #fff;
         margin-top: 1.2rem;
         margin-right: 1.2rem;
         width: calc(50% - 1.2rem);
         font-size: 1.4rem;
         display: inline-block;
         padding: 1.1rem 1.1rem;
         border-radius: .4rem;
         text-align: center;
         border: 1px solid #222433; 
         font-family: 'Stolzl-Medium';
         cursor: pointer;
      }
   }
}
@media only screen and (max-width: 1100px) {
   .main {
      width: 100%;

   }
 }
 @media only screen and (max-width: 600px) {
   .main {
      width: 80%;
      max-width: 50rem;
      margin: 0 auto;
      height: auto;
      border-radius: 5px;
      .title {
         font-size: 2rem;
      }
      .subTitle {
         font-size: 1.3rem;
      }
      form {
         input {
            margin-right: 0;
            width: 100%
         }
         .btn {
            margin-top: 1.2rem;
            margin-right: 0;
            width: 100%;
         }
      }
   }
 }
 @media only screen and (max-width: 480px) {
   .main {
      width: 100%;
    
   }
 }