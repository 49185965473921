.main {
  width: 100%;
  background-color: #222433;
  .footerTop {
    padding-bottom: 4.4rem;
    background-image: url(./assets/footer-bg.svg);
    background-repeat: no-repeat;
    background-position: top 2.4rem right -4.8rem;
    padding-top: 5rem;
    nav {
      display: flex;
      justify-content: space-between;
      max-width: 108rem;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        a {
          margin: 0 1.2rem 2.4rem;
          font-size: 1.8rem;
          line-height: 120%;
          color: #fff;
          transition: 0.3s;
          text-align: center;
        }
        a:hover {
          color: #8f7578;
        }
        .socBtns {
          display: flex;
          flex-direction: row;
          svg {
            margin-right: 25px;
            cursor: pointer;
          }
          svg:hover path {
            stroke: #8f7578 !important;
          }
          svg:hover .fillInHover {
            fill: #8f7578 !important;
          }
        }
      }
    }
  }
  .footerBottom {
    width: 100%;
    padding: 2.3rem 1rem;
    background-color: #1d1e2b;
    display: flex;
    justify-content: center;
    span {
      font-size: 1.4rem;
      line-height: 120%;
      color: #50515a;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    .footerTop {
      nav {
        div {
          width: 50%;
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .main {
    .footerTop {
      nav {
        div {
          width: 100%;
        }
      }
    }
  }
}
